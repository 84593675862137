import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import Landing from '../templates/Landing';

// const host = process.env.GATSBY_HOST;

const knowledge = {
  header: {
    title: 'knowledgeHeaderTitle',
    subTitle: 'knowledgeHeaderSubTitle',
    image: 'deepmemo-logo.png',
    // button1: {
    //   text: 'registration',
    //   subText: 'registrationSubText',
    //   link: `${host}/signup#formNewSite`,
    //   event: 'Header / Get Started'
    // },
    button2: {
      text: 'orderDemo',
      link: '/form/live-demo',
      event: 'KNOW_request_demo'
    }
  },
  content: [
    {
      title: 'knowledgeContentBaseTitle',
      subTitle: 'knowledgeContentBaseSubTitle',
      image: 'famaly.png',
    },
    {
      title: 'knowledgeContentBillingTitle',
      subTitle: 'knowledgeContentBillingSubTitle',
      image: 'gas.png',
    },
    {
      title: 'knowledgeContentEscalTitle',
      subTitle: 'knowledgeContentEscalSubTitle',
      image: 'dnk.png',
    },
    {
      title: 'knowledgeContentAntifraudTitle',
      subTitle: 'knowledgeContentAntifraudSubTitle',
      image: 'shield.png',
    },
    {
      title: 'knowledgeContentSuperTitle',
      subTitle: 'knowledgeContentSuperSubTitle',
      image: 'briliant.png',
    }
  ],
  demo: {
    event: 'digital_core_start'
  }
};

const Knowledge = (props) => (
  <Layout {...props}>
    <Landing
      data={knowledge}
    />
  </Layout>
);

export default withIntl(Knowledge);

